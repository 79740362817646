// @flow
import React, { PureComponent } from 'react';

import Label from 'components/fragments/text/Label';
import TopBarUserConnection from 'containers/header/TopBarUserConnectionContainer';
import type { MenuElementType } from 'types/MenuElement';
import type { RouterProps } from 'types/Router';
import { handleTagEvent } from 'utils/tagManagerUtils';
import { dynamicClassName } from 'utils/dynamicClassName';

import './TopBarStyle.css';

export type DispatchProps = {
  fetchArticlesBillboard: () => void
};

type StateProps = {
  structure_parente: {
    id: string,
    nom: string,
    code: string,
    siteInternet: string
  },
  topBarElements: MenuElementType[],
  tickerElements: {
    link: string,
    title: string,
    label: string,
    featured_image: string
  }
};

type Props = DispatchProps &
  StateProps &
  RouterProps & {
    authenticated: boolean,
    isMobile?: boolean
  };

type State = {
  isAccordionOpen: boolean
};

class TopBar extends PureComponent<Props, State> {
  static defaultProps = {
    topBarElements: []
  };

  state: State = {
    isAccordionOpen: false
  };

  wrapperRef: any;

  onLinkClick = (link: string) => {
    return handleTagEvent('menu_transverse', 'clic_lien_sortant', link);
  };

  renderTopBarElements = () => {
    const { topBarElements } = this.props;
    const menus = topBarElements.filter(item => item.parent === 0);

    return menus.map<any>(({ items, title, url }, index) => {
      if (items && items.length > 0) {
        return false;
      }

      return (
        <li key={index}>
          <a
            href={url}
            title={`Se rendre sur le site officiel "${title}" de la Fédération Française de Rugby (nouvel onglet)`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onLinkClick(url)}
          >
            {title}
          </a>
        </li>
      );
    });
  };

  renderTopBarLigue = () => {
    const {
      structure_parente: { id, nom, siteInternet }
    } = this.props;
    let leagueName = nom;

    if (leagueName && leagueName.length > 14) {
      leagueName = `${leagueName.slice(0, 14)}...`;
    } else if (!leagueName) {
      return null;
    }

    leagueName = leagueName
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return (
      <li key={id} className="league-link">
        <a
          href={siteInternet}
          title={`Se rendre sur le site officiel "${nom}" de la Fédération Française de Rugby (nouvel onglet)`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.onLinkClick(siteInternet)}
        >
          {`Ligue ${leagueName ?? ''}`}
        </a>
      </li>
    );
  };

  handleAccordionOpen = () => {
    this.setState(prevState => ({ isAccordionOpen: !prevState.isAccordionOpen }));
  };

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  render() {
    const {
      isMobile,
      tickerElements: { link, label, title }
    } = this.props;

    const { isAccordionOpen } = this.state;

    // CLASSES
    const topbarDynamicClasses = dynamicClassName('topbar');

    if (isAccordionOpen) {
      topbarDynamicClasses.add('has-accordion-open');
    } else {
      topbarDynamicClasses.remove('has-accordion-open');
    }

    return (
      <nav className="topbar">
        <ul className="topbar__left">
          {this.renderTopBarElements()}

          {this.renderTopBarLigue()}

          {link && title && label && (
            <li className="topbar__news">
              <a href={link} title={title} target="_blank" rel="noopener noreferrer">
                <Label isSmall isBlueAlt label={label || 'La fédération'} />
                <span>{title}</span>
              </a>
            </li>
          )}

          {!isMobile && <TopBarUserConnection handleUserAccordionOpen={this.handleAccordionOpen} />}

          <li className="display-desktop-only btn btn--red-dark">
            <a target="_blank" href="https://report.whistleb.com/fr/ffr" rel="noreferrer">
              J&apos;alerte
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default TopBar;
