// @flow

import React, { useState, useRef } from 'react';
import TopBar from 'containers/header/TopBarContainer';
import TopBarUserConnection from 'containers/header/TopBarUserConnectionContainer';
import { dynamicClassName } from 'utils/dynamicClassName';

function TopBarMobile() {
  const topBarRef: any = useRef(null);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isTopBarOpen, setIsTopBarOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (topBarRef.current && !topBarRef.current.contains(event.target)) {
      setIsTopBarOpen(false);
    }
  };

  const handleUniversFFRClick = (e: MouseEvent) => {
    if (!isTopBarOpen) {
      document.addEventListener('mousedown', handleClickOutside, { once: true });
      setIsTopBarOpen(true);
    }

    setIsTopBarOpen(!isTopBarOpen);
  };

  const navClasses = dynamicClassName('topbar mobile-tablet-only');
  isUserDropdownOpen && navClasses.add('has-accordion-open');
  !isUserDropdownOpen && navClasses.remove('has-accordion-open');

  const topBarLeftClasses = dynamicClassName('topbar--mobile__left');
  isTopBarOpen && topBarLeftClasses.add('is-open');

  return (
    <nav id="topbar--mobile" className={navClasses.build()}>
      <ul>
        <li ref={topBarRef} className={topBarLeftClasses.build()}>
          <button className="topbar--mobile__button" onClick={handleUniversFFRClick}>
            <div>Univers FFR</div>
            <i className="icon icon-chevron-down" />
          </button>
          <div className={`topbar--mobile__links ${isTopBarOpen ? 'is-visible' : ''}`}>
            <TopBar isMobile setIsTopBarOpen={setIsTopBarOpen} />
          </div>
        </li>

        <li className="topbar--mobile_important-link">
          <a target="_blank" href="https://report.whistleb.com/fr/ffr" rel="noreferrer">
            J&apos;alerte
          </a>
        </li>

        <TopBarUserConnection isMobile handleUserAccordionOpen={setIsUserDropdownOpen} />
      </ul>
    </nav>
  );
}

export default TopBarMobile;
