// @flow

import { type ResultCalendarType, type StructureType, nullStructure, nullResultCalendar } from 'types/Result';

export type JoueurType = {
  id: string,
  nom: string,
  prenom: string,
  displayName: string
};

export type RencontreActionType = {
  id: string,
  minutes: number,
  periode: number,
  type: string,
  sousType: string,
  equipe: StructureType,
  joueur: ?JoueurType,
  joueur2: ?JoueurType,
  isLocal?: boolean
};

type ActionType = 'debut_match' | 'essai' | 'transformation' | 'drop' | 'penalite' | 'carton jaune' | 'carton rouge' | 'remplacement';

export type RencontreJoueurType = {
  id: string,
  grade: string,
  estCapitaine: boolean,
  estD: boolean,
  estRemplacant: boolean,
  estPremiereLigne: boolean,
  estG: boolean,
  estStarter: boolean,
  estT: boolean,
  numero: number,
  position: number,
  nom: string,
  prenom: string,
  idPersonne: string
};

export type RencontreActionContainerType = {
  type: ActionType,
  localActions: RencontreActionType[],
  visiteurActions: RencontreActionType[],
  globalActions: RencontreActionType[]
};

export type RencontreType = ResultCalendarType & {
  forfait: boolean,
  actions: RencontreActionContainerType[],
  competitionPhaseId: string,
  classeAgeId: string,
  localJoueurs: RencontreJoueurType[],
  visiteurJoueurs: RencontreJoueurType[],
  competitionEquipeLocaleId: string,
  competitionEquipeVisiteurId: string,
  eligibleFDMD: boolean
};

export type RencontreResultSimpleType = {
  pointsDeMarque: number
};

export type RawStructureType = {
  id: string,
  nom: string,
  Structure: {
    id: string,
    nom: string,
    embleme: string
  },
  Regroupement: {
    id: string,
    nom: string,
    embleme: string
  }
};

export type RawRencontreEquipeJoueur = {
  id: string,
  grade: string,
  estCapitaine: boolean,
  estD: boolean,
  estRemplacant: boolean,
  estPremiereLigne: boolean,
  estG: boolean,
  estStarter: boolean,
  estT: boolean,
  numero: number,
  position: number,
  Personne: {
    id: string,
    nom: string,
    prenom: string
  }
};

export type RawRencontreSimpleType = {
  id: string,
  dateEffective: Date,
  dateEffectiveStr: string,
  dateOfficielle: Date,
  Competition: {
    id: string,
    nom: string,
    identifiant: string,
    Famille: {
      nom: string
    },
    ClasseAge: {
      Sexe: {
        code: string
      },
      code: string
    }
  },
  Journee: { nom: string },
  RencontreResultatVisiteuse: RencontreResultSimpleType,
  RencontreResultatLocale: RencontreResultSimpleType,
  CompetitionEquipeLocale: RawStructureType,
  CompetitionEquipeVisiteuse: RawStructureType,
  EquipeLocal: RawRencontreEquipeJoueur[],
  EquipeVisiteur: RawRencontreEquipeJoueur[],
  Etat: { nom: string },
  etatMixte: string
};

export type RencontreSimpleType = {
  id: string,
  visiteurStructure: StructureType,
  localStructure: StructureType,
  resultLocal: RencontreResultSimpleType,
  resultVisiteur: RencontreResultSimpleType,
  dateEffective: Date,
  dateEffectiveStr: string,
  competition: { nom: string, nomFamille: string, classeAgeCode: string, sexeCode: string },
  journee: { nom: string }
};

export type RencontreLiveType = {
  id: string,
  visiteurStructure: StructureType,
  localStructure: StructureType,
  competitionEquipeLocaleId: string,
  competitionEquipeVisiteurId: string,
  classeAgeId: string,
  actions: RencontreActionContainerType[],
  etat: string,
  competition: { id: string, nom: string, nomFamille: string, classeAgeCode: string, sexeCode: string }
};

export const nullRencontreAction: RencontreActionType = {
  id: '11',
  minutes: 0,
  periode: 0,
  type: '',
  sousType: '',
  joueur: null,
  joueur2: null,
  equipe: nullStructure
};

export type RencontreArticleType = {
  id: string,
  etat: string,
  journee: string,
  competition: { nom: string, classeAgeCode: string },
  localStructure: StructureType,
  visiteurStructure: StructureType,
  pointsStructureLocale: 0,
  pointsStructureVisiteur: 0,
  dateEffective: Date,
  dateEffectiveStr: string,
  dateOfficielle: Date,
  forfait: boolean,
  lien?: string
};

export const nullRencontreArticle: RencontreArticleType = {
  id: '',
  etat: '',
  journee: '',
  competition: { nom: '', classeAgeCode: '' },
  localStructure: nullStructure,
  visiteurStructure: nullStructure,
  pointsStructureLocale: 0,
  pointsStructureVisiteur: 0,
  dateEffective: new Date(),
  dateEffectiveStr: '',
  dateOfficielle: new Date(),
  forfait: false,
  lien: ''
};

export const nullRencontreJoueur: RencontreJoueurType = {
  id: '',
  grade: '',
  estCapitaine: false,
  estD: false,
  estRemplacant: false,
  estPremiereLigne: false,
  estG: false,
  estStarter: false,
  estT: false,
  numero: 0,
  position: 0,
  nom: '',
  prenom: '',
  idPersonne: ''
};

export const nullRencontre: RencontreType = {
  ...nullResultCalendar,
  forfait: false,
  competitionEquipeLocaleId: '',
  competitionEquipeVisiteurId: '',
  competitionPhaseId: '12',
  classeAgeId: '0',
  eligibleFDMD: false,
  localJoueurs: [],
  visiteurJoueurs: [],
  actions: [
    {
      type: 'debut_match',
      localActions: [nullRencontreAction],
      visiteurActions: [nullRencontreAction],
      globalActions: [nullRencontreAction]
    },
    {
      type: 'transformation',
      localActions: [nullRencontreAction],
      visiteurActions: [nullRencontreAction],
      globalActions: [nullRencontreAction]
    },
    {
      type: 'essai',
      localActions: [nullRencontreAction],
      visiteurActions: [nullRencontreAction],
      globalActions: [nullRencontreAction]
    },
    {
      type: 'drop',
      localActions: [nullRencontreAction],
      visiteurActions: [nullRencontreAction],
      globalActions: [nullRencontreAction]
    },
    {
      type: 'penalite',
      localActions: [nullRencontreAction],
      visiteurActions: [nullRencontreAction],
      globalActions: [nullRencontreAction]
    },
    {
      type: 'carton rouge',
      localActions: [nullRencontreAction],
      visiteurActions: [nullRencontreAction],
      globalActions: [nullRencontreAction]
    },
    {
      type: 'carton jaune',
      localActions: [nullRencontreAction],
      visiteurActions: [nullRencontreAction],
      globalActions: [nullRencontreAction]
    },
    {
      type: 'remplacement',
      localActions: [nullRencontreAction],
      visiteurActions: [nullRencontreAction],
      globalActions: [nullRencontreAction]
    }
  ]
};

export const nullRencontreSimple: RencontreSimpleType = {
  id: '123456789',
  visiteurStructure: nullStructure,
  localStructure: nullStructure,
  resultLocal: {
    pointsDeMarque: 0
  },
  resultVisiteur: {
    pointsDeMarque: 0
  },
  dateEffective: new Date(),
  dateEffectiveStr: '',
  competition: { nom: '', classeAgeCode: '', nomFamille: '', sexeCode: '' },
  journee: { nom: '' }
};
