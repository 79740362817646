// @flow
import React, { PureComponent } from 'react';
import socketIOClient from 'socket.io-client';

import CardLiveMatch from 'components/fragments/card/CardLiveMatch';

import type { RencontreActionContainerType, RencontreLiveType } from 'types/Rencontre';
import type { CompetitionEquipeType } from 'types/Settings';

import { getMatchScore, getLastMinute } from 'utils/rencontreUtils';
import { getCompetitionTag } from 'utils/graphResponseUtils';
import { dynamicClassName } from 'utils/dynamicClassName';

import { convertActionFromWS, mergeActions } from 'services/Rencontre';

import { WS_BASE_URL } from 'constants/api';

export type StateProps = {
  rencontresLive: {
    [key: string]: RencontreLiveType,
  },
  competitionsEquipes: CompetitionEquipeType,
};

export type DispatchProps = {
  setRencontreLive: (id: string, actions: RencontreActionContainerType[]) => void,
  removeRencontreLive: (id: string) => void,
};

type Props = DispatchProps & StateProps;

type State = {
  rencontres: {
    [key: string]: RencontreActionContainerType[],
  },
  isOpen: boolean,
  socketOpen: string[],
};

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
// $FlowFixMe
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
// $FlowFixMe
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

class LiveScore extends PureComponent<Props, State> {
  socket: ?socketIOClient = null;

  state: State = {
    rencontres: {},
    socketOpen: [],
    isOpen: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { rencontres } = prevState;
    const { rencontresLive } = nextProps;

    const rencontresLiveIds = Object.keys(rencontresLive);

    if (rencontresLiveIds.length > 0) {
      const nextRencontres = {};

      rencontresLiveIds.forEach((rencontre) => {
        if (!Object.prototype.hasOwnProperty.call(rencontres, rencontre)) {
          nextRencontres[rencontre] = rencontresLive[rencontre].actions;
        }
      });

      return {
        rencontres: {
          ...nextRencontres,
          ...rencontres,
        },
      };
    }

    return null;
  }

  onUpdateAction = (id: string) => (data: any) => {
    const {
      setRencontreLive,
      //removeRencontreLive,
      rencontresLive,
    } = this.props;

    if (!rencontresLive || !rencontresLive[id]) {
      return;
    }

    const {
      competitionEquipeLocaleId,
      competitionEquipeVisiteurId,
    } = rencontresLive[id];

    const newAction = data;
    if (newAction.type === 'MATCHOVER') {
      window.location.reload(true);
    } else {
      const actions = convertActionFromWS(newAction);

      this.setState((prevState) => ({
        rencontres: {
          [String(id)]: mergeActions(
            prevState.rencontres[String(id)],
            actions,
            parseInt(competitionEquipeLocaleId, 10),
            parseInt(competitionEquipeVisiteurId, 10)
          ),
        },
      }), () => setRencontreLive(id, this.state.rencontres[String(id)]));
    }
  };

  handleVisibilityChange() {
    // $FlowFixMe
    if (!document[hidden] && (!this.socket || !this.socket.connected)) {
      /**
       * TODO : devrait être améliorable
       */
      window.location.reload(true);
    }
  }

  componentDidMount() {
    const socket = socketIOClient(WS_BASE_URL, { transports: ['websocket'] }); 
    this.socket = socket;

    const { rencontresLive } = this.props;

    const liveMatchs = Object.keys(rencontresLive);
    const liveLength = liveMatchs.length;
    if (liveLength !== 0) {
      // $FlowFixMe
      document.addEventListener(visibilityChange, this.handleVisibilityChange.bind(this), false);
    }
  }

  componentWillUnmount() {
    this.socket && this.socket.close();
    // $FlowFixMe
    document.removeEventListener(visibilityChange, this.handleVisibilityChange.bind(this));
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { rencontresLive } = this.props;
    const rencontresLiveIds = Object.keys(rencontresLive);

    if (this.socket !== null) {
      if (rencontresLiveIds.length !== Object.keys(prevProps.rencontresLive).length) {
        const { socketOpen: nextSocketOpen } = this.state;

        rencontresLiveIds.filter((rencontre) => nextSocketOpen.includes(rencontre));

        if (rencontresLiveIds.length > 0) {
          // $FlowFixMe
          document.addEventListener(visibilityChange, this.handleVisibilityChange.bind(this), false);
          rencontresLiveIds.forEach((rencontre_id) => {
            this.socket && this.socket.emit('subscribe', rencontre_id);
            this.socket && this.socket.on(rencontre_id, this.onUpdateAction(rencontre_id));

            nextSocketOpen.push(rencontre_id);
          });

          this.setState({ socketOpen: nextSocketOpen });
        } else {
          // $FlowFixMe
          document.removeEventListener(visibilityChange, this.handleVisibilityChange.bind(this));
        }
      }
    }
  }

  getRencontreLink = (competitionId: string, rencontreId: string) => {
    const { competitionsEquipes } = this.props;
    const matchLink = `match/${rencontreId}`;

    if (Object.prototype.hasOwnProperty.call(competitionsEquipes, competitionId)) {
      const baseLink =  competitionsEquipes[competitionId].equipe_slug;

      return `${baseLink}/${matchLink}`;
    }

    return `/${matchLink}`;
  };

  _renderLiveCards = (): any[] | null => {
    const { rencontres } = this.state;
    const { rencontresLive } = this.props;
    const rencontresLiveIds = Object.keys(rencontresLive);

    if (rencontresLiveIds.length === 0) {
      return null;
    }

    return rencontresLiveIds.map((match) => {
      const { id, localStructure, visiteurStructure, competition: rawCompetition } = rencontresLive[match];
      const competition = getCompetitionTag(rawCompetition);
      const matchLink = this.getRencontreLink(rawCompetition.id, id);

      if (Object.prototype.hasOwnProperty.call(rencontres, match)) {
        const { scoreVis, scoreLoc } = getMatchScore(rencontres[match]);
        const lastMinute = getLastMinute(rencontres[match]);

        return (
          <CardLiveMatch
            key={match}
            localStructure={localStructure}
            visiteurStructure={visiteurStructure}
            localScore={String(scoreLoc) || '0'}
            visiteurScore={String(scoreVis) || '0'}
            minutes={String(lastMinute) || '0'}
            competition={competition}
            url={matchLink}
          />
        );
      }

      return (
        <CardLiveMatch
          key={match}
          localStructure={localStructure}
          visiteurStructure={visiteurStructure}
          localScore='0'
          visiteurScore='0'
          minutes='0'
          competition={competition}
          url={matchLink}
        />
      );
    });
  };

  setOpen = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { isOpen } = this.state;
    const { rencontresLive } = this.props;

    const liveMatchs = Object.values(rencontresLive);
    // $FlowFixMe.
    const liveLength = liveMatchs.filter((rencontre) => rencontre.etat && rencontre.etat !== 'Score validé').length;

    if (liveLength === 0) {
      return '';
    }

    const accordionClassName = dynamicClassName('accordion-panel');
    !isOpen && accordionClassName.add('is-hidden');

    const liveResultClassName = dynamicClassName('live-results');
    isOpen && liveResultClassName.add('has-accordion-open');


    return (
      <div className={liveResultClassName.build()}>
        <div className="container">
          <div className="live-results__head">
            <div className="container">
              <div>
                <span className="label">
                  En direct
                </span>
                <span className="live-results__game-count ft-500">
                  {`${liveLength} match${liveLength > 1 ? 's' : ''}`}
                  &nbsp; en cours
                </span>
              </div>
              <span
                onClick={this.setOpen}
                role="button"
                tabIndex={0}
                className="accordion-trigger dropdown__head"
              >
                Voir plus
              </span>
            </div>
          </div>
          <div className={accordionClassName.build()}>
            <div className="container">
              <div className="live-results__wrapper">
                <>
                  {this._renderLiveCards()}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LiveScore;
